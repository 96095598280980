.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* question page css */

.questions-option-main-sec {
  padding: 50px 20px 35px;
  /* background: url("../src/assets/images/question-page-bg-img1.jpg") no-repeat; */
  background-size: cover;
  min-height: 100vh;
  position: relative;
  z-index: 4;
  background: url("../src/assets/images/cmn-all-website-bnr-bg.jpg");
}

.questions-option-main-sec::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  content: "";
  z-index: -1;
  background: #131313;
  display: none;
}

.question-starting-main {
  background: #fff;
  box-shadow: 0 0 8px #c8cee0;
  padding: 30px 25px;
  border-radius: 8px;
}

.question-starting-main .all-questions-inr-options:not(:first-child) {
  margin-top: 15px;
}

.all-questions-inr-options h2 {
  color: #282c34;
  font-size: 40px;
  line-height: 45px;
  text-transform: capitalize;
  margin-bottom: 25px;
  padding: 0 0 15px 28px;
  position: relative;
  border-bottom: 1px solid #9db7ff;
}

.all-questions-inr-options h2::before {
  position: absolute;
  left: 0;
  top: 15px;
  width: 20px;
  height: 20px;
  background: #002ba5;
  border-radius: 4px;
  content: "";
}

.questions-options .answer-details-inr input[type="radio"] {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: middle;
}

.answer-details-inr {
  background: #fff;
  box-shadow: 0 0 20px #00266c;
  margin-bottom: 20px;
  padding: 25px 15px;
  border-radius: 0 20px 0 20px;
}

.answer-details-inr label {
  color: #302d2b;
  font-size: 16px;
  line-height: 22px;
  text-transform: capitalize;
}

.last-submit-next-btn-sec {
  margin: 20px 0 12px;
  text-align: center;
}

.last-submit-next-btn-sec button {
  margin-left: 16px;
  min-width: 100px;
  border-radius: 4px;
  cursor: pointer;
}

.last-submit-next-btn-sec button:first-child {
  margin-left: 0;
}

.submit-btn {
  background: #008b1a;
  border: 1px solid #008b1a;
  padding: 10px 15px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
}

.submit-btn:hover {
  color: #008b1a;
  box-shadow: inset 0 70px #fff;
  transform: scale(1.1);
}

.prev-btn {
  background: #bb5400;
  border: 1px solid #bb5400;
  padding: 10px 15px;
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
}

/* .prev-btn:hover{
  color: #bb5400;
  box-shadow: inset 0 0 0 90px #fff;
  transform: scale(1.1);
} */

.next-btn {
  background: #1a37ff;
  border: 1px solid #1a37ff;
  padding: 10px 15px;
  color: #fff;
  font-size: 17px;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
}

/* .next-btn:hover{
  color: #1a37ff;
  box-shadow: inset 200px 0 #fff;
  transform: scale(1.1);
} */

/* tests sec */

.test-btn-all-main-sec {
  background: url("../src/assets/images/cmn-all-website-bnr-bg.jpg") no-repeat;
  padding: 50px 20px;
  min-height: 100vh;
  background-position: center;
  backdrop-filter: blur(50px);
  background-size: cover;
  /* position: relative; */
}

/* .test-btn-all-main-sec::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #1a1a1a;
  content: "";
  opacity: 0.4;
  z-index: -1;
} */

.testing-btn-inr [class*="col-"] {
  text-align: center;
  margin-bottom: 25px;
}

.testing-btn-inr button {
  cursor: pointer;
  background: #fffb5e;
  border: 1px solid #cdcdcd;
  padding: 12px 15px;
  color: #171717;
  font-size: 17px;
  font-weight: 600;
  background-size: 200% auto;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  min-width: 280px;
}

.testing-btn-inr button:hover {
  transform: scale(1.1);
  color: #030039;
  text-decoration: none;
}

.heading-one-cmntest-que {
  text-align: center;
  margin-bottom: 35px;
  position: relative;
  padding-bottom: 16px;
}

.heading-one-cmntest-que::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 25%;
  height: 2px;
  background: #b5fdfc;
  content: "";
  border-radius: 10px;
}

.heading-one-cmntest-que h2 {
  color: #fff;
  font-size: 30px;
  line-height: 26px;
  text-transform: capitalize;
  margin-bottom: 12px;
}

/* header and logout sec */

.header-main-sec {
  background: url("../src/assets/images/header-bg.jpg") no-repeat;
  background-position: top center;
  background-size: cover;
  /* box-shadow: 0 0 20px #89a5fb; */
  box-shadow: 0 0 40px #ffcc59;
  padding: 15px 0;
  position: relative;
  z-index: 99;
}

.header-main-sec::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #232323;
  opacity: 0.8;
  z-index: -1;
}

.header-inr-sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo-sec-header a {
  display: inline-block;
}

.logo-sec-header a img {
  max-width: 230px;
  height: 90px;
  object-fit: contain;
}

@media (max-width: 479px) {
  .logo-sec-header a img {
    width: 205px;
  }
}

.right-header-logout-sec button {
  background-image: linear-gradient(to right,
      #c21500 0%,
      #ffc500 51%,
      #c21500 100%);
  padding: 7px 12px;
  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 12px #93a1c9;
  border-radius: 5px;
  font-size: 16px;
  line-height: 25px;
  border: 1px solid #3ca55c;
}

.logout-btn:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
  transform: scale(1.1);
}

.all-questions-inr-options h6 {
  font-size: 26px;
  color: #282828;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 15px;
}

.questions-options {
  padding-top: 12px;
}

.all-questions-inr-options h6 p {
  font-size: 16px;
  color: #363636;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 15px;
}

/* sidebar */

.custom-sidebar-btn-sec-main .btn-toggle-sidebar {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.custom-sidebar-btn-sec-main .btn-toggle-sidebar img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.sidebar__box__listing ul li>div:hover {
  background: #00ab45;
  color: #fff;
}

.right-sec-header-sidebar-flex-main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 10px;
}

.heading__sidebarbox {
  padding: 10px 8px 5px;
}

.heading__sidebarbox h3 {
  color: #282c34;
  font-size: 22px;
  line-height: 25px;
  text-transform: capitalize;
  position: relative;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7e2ff;
}

/* custom stable sidebar */

.flex-question-sidebars-sec [class*="col-"] {
  margin-bottom: 20px;
}

.stable-sidebar-main-sec {
  background: #fff;
  padding: 15px 10px 20px;
  border-radius: 8px;
  height: 100%;
}

.sidebar__question-items {
  max-height: 300px;
  overflow-x: auto;
}

.sidebar__question-items ul {
  display: flex;
  gap: 12px 8px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 8px 8px;
}

.sidebar__question-items ul li {
  list-style: none;
  background: #fcff6b;
  border: none;
  color: #181818;
  border-radius: 30px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  justify-content: center;
  cursor: pointer;
  width: 47px;
  height: 47px;
  text-align: center;
  box-shadow: 0 0 10px #8b8b8b;
  outline: none;
  text-decoration: none;
}

.sidebar__question-items ul li.active__question-set {
  background: #00ab45 !important;
  color: #fff !important;
  border-radius: 30px;
  border: 1px solid #fff;
}

.sidebar__question-items ul li:hover,
.sidebar__question-items ul li:focus {
  background: #00ab45;
  color: #fff;
  border: 1px solid #fff;
}

.sidebar__question-items ul li.unattempted__questions-set {
  background: #fff;
  color: #181818;
}

/* course practice tests */

.cram-course-main-sec {
  padding: 60px 15px;
  /* background: url("../src/assets/images/army-home-banner-1.jpg") no-repeat; */
  background: url("../src/assets/images/cmn-all-website-bnr-bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  /* position: relative;
  z-index: 4; */
  min-height: 100vh;
}

/* .cram-course-main-sec::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  content: "";
  z-index: -1;
  background: #131313;
} */

.cram-course-main-sec .row {
  justify-content: center;
}

.cram-course-main-sec .cram-course-inr-details-sec {
  margin-bottom: 28px;
}

.cram-course-inr-details-sec {
  text-align: center;
  padding: 30px 16px;
  border-radius: 6px;
  background-image: linear-gradient(to right,
      #16a085 0%,
      #f4d03f 51%,
      #16a085 100%);
  transition: 0.6s;
  background-size: 200% auto;
}

.cram-course-inr-details-sec:hover {
  background-position: right center;
  transform: scale(1.1);
  box-shadow: inset 400px 0 #16a085;
}

.icon-sec-inr-cram-course {
  margin-bottom: 15px;
}

.icon-sec-inr-cram-course img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  filter: invert(1) brightness(12);
}

.cram-course-inr-details-sec h3 {
  font-size: 26px;
  color: #fff;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 12px;
}

.cram-course-inr-details-sec p {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  line-height: 18px;
  margin-bottom: 12px;
}

.btn-sec-last-cram-course {
  padding-top: 16px;
}

.all-cmn-btn-cram-courses {
  background-image: linear-gradient(to right,
      #00d2ff 0%,
      #3a7bd5 51%,
      #00d2ff 100%);
  padding: 12px 25px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 12px #97b0f5;
  border-radius: 5px;
  font-size: 15px;
  line-height: 25px;
  border: 1px solid #00d2ff;
}

.all-cmn-btn-cram-courses:hover {
  background-position: right center;
  color: #fff;
  text-decoration: none;
}

/* .cram-course-inr-details-sec:hover .all-cmn-btn-cram-courses{
  background-position: right center;
  color: #fff;
} */

/* flashcards */

.flashcards-pages-main-sec {
  padding: 50px 0;
  /* background: url("../src/assets/images/flash-card-bg-1.jpg") no-repeat; */
  background: url("../src/assets/images/cmn-all-website-bnr-bg.jpg") no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 100vh;
  /* position: relative;
  z-index: 3; */
}

/* .flashcards-pages-main-sec::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  content: "";
  z-index: -1;
  background: #131313;
} */

.flashcards-inr-sec .row {
  justify-content: center;
}

.flashcards__inrcol-details {
  box-shadow: 0 0 20px #026d85;
  background: #fff;
  border-radius: 10px 30px 10px 30px;
  text-align: center;
  padding: 60px 35px;
  position: relative;
  word-wrap: break-word;
  /* animation: pulse 5s linear infinite; */
  background: #ffe8d0;
}

.flashcards-heading-sec {
  margin-bottom: 40px;
  padding-bottom: 15px;
  position: relative;
  text-align: center;
}

.flashcards-heading-sec::before {
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 40%;
  height: 1px;
  content: "";
  background: #8abfff;
}

.flashcards-heading-sec h2 {
  font-size: 32px;
  /* color: #282828; */
  color: #fff;
  font-weight: 700;
  line-height: 35px;
  margin-bottom: 8px;
}

.flashcards__inrcol-details h3 {
  font-size: 27px;
  color: #282828;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.click-wise-btn-flashcards {
  text-align: center;
  margin-top: 35px;
  display: inline-block;
}

.flip__btn-flashcard {
  background-image: linear-gradient(to right,
      #34e89e 0%,
      #0f3443 51%,
      #34e89e 100%);
  padding: 12px 40px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 12px #002ca5;
  border-radius: 5px;
  font-size: 16px;
  line-height: 25px;
  outline: none;
  border: none;
}

.flip__btn-flashcard:hover {
  background-position: right center;
  color: #fff;
  transform: scale(1.1);
}

.answers__flashcards-inr {
  position: absolute;
  padding: 50px 25px 45px;
  left: 50%;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px 30px 10px 30px;
  transform: translateX(-50%) rotateY(180deg);
  transition: 0.7s;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  background-image: linear-gradient(to right, #6a9113 0%, #55750e 100%)
}

.close__btn-click-answers {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a30202;
  border-radius: 50px;
  border: none;
}

.close__btn-click-answers img {
  width: 14px;
  height: 14px;
  object-fit: contain;
  filter: invert(1) brightness(12);
}

/* btn click to active class in flashcards */
.answers__flashcards-inr.attach__answers-with-btnclick {
  transform: translateX(-50%) rotateY(0);
  width: 100%;
  z-index: 3;
  opacity: 1;
  visibility: visible;
}

.answers__flashcards-inr p {
  font-size: 19px;
  color: #fff;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 10px;
}

.last-btn-prev-next-sec {
  margin-top: 25px;
  text-align: center;
}

.last-btn-prev-next-sec button {
  background-image: linear-gradient(to right,
      #1a2980 0%,
      #26d0ce 51%,
      #1a2980 100%);
  border: 1px solid rgb(50, 78, 238);
  padding: 12px 30px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  background-size: 200% auto;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  margin-right: 10px;
}

.last-btn-prev-next-sec button:hover {
  background-position: right center;
}

.last-btn-prev-next-sec button:last-child {
  margin-right: 0;
}

.last-btn-prev-next-sec button img {
  width: 18px;
  height: 18px;
  filter: invert(1) brightness(12);
  object-fit: contain;
  margin: 0 2px;
}
/* /// */

.last-btn-prev-next-sec-ref {
  text-align: center;
  margin-bottom: 25px;
}

.last-btn-prev-next-sec-ref button {
 
  background-image: linear-gradient(90deg, #f9ffae 0, #ffe002 51%, #fff930);
    border: 1px solid #81836d;
  padding: 12px 30px;
  color: black;
  font-size: 16px;
  font-weight: 600;
  background-size: 200% auto;
  line-height: 22px;
  text-transform: capitalize;
  transition: 0.5s;
  outline: none;
  border-radius: 5px;
  margin-right: 10px;
}

.last-btn-prev-next-sec-ref button:hover {
  background-position: right center;
}

.last-btn-prev-next-sec-ref button:last-child {
  margin-right: 0;
}

.last-btn-prev-next-sec-ref button img {
  width: 18px;
  height: 18px;
  filter: invert(1) brightness(12);
  object-fit: contain;
  margin: 0 2px;
}

.ref-list:hover {
  background-color: #888b14;
  color: #fff;
  border-radius: 5px;
}

/* animation css */

@keyframes pulse {
  0% {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.subject-header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #282828;
  font-weight: 700;
  letter-spacing: 2px;
  border-bottom: 2px solid white;
  width: 100%;
  line-height: 22px;
  background: #fff;
  box-shadow: 0 0 8px #c8cee0;
  border-radius: 40px 40px 5px 5px;
  margin-bottom: 20px;
}

.subject-header h3 {
  font-size: 26px;
  color: #282828;
  font-weight: 700;
  text-align: center;
}

/* Custom Scroll */
.sidebar__question-items::-webkit-scrollbar {
  width: 8px;
}

.sidebar__question-items::-webkit-scrollbar-thumb {
  /* background: #bcbcbc; */
  background: rgb(73, 41, 255);
  border-radius: 10px;
}

.sidebar__question-items::-webkit-scrollbar-track {
  background: #ededed;
  border-radius: 10px;
}

/* Custom Scroll */

/* Custom Scroll */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  /* background: #bcbcbc; */
  background: rgb(103, 77, 255);
  border-radius: 15px;
}

body::-webkit-scrollbar-track {
  background: rgb(212, 212, 212);
  border-radius: 10px;
}

/* Custom Scroll */

.nav-links a {
  display: inline-block;
  padding: 10px 20px;
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}

.nav-links a:hover {
  color: #939393;
}

.nav-links a.active {
  color: #f8b100;
}

.nav-links a:not(:last-child) {
  border-right: 1px solid #636363;
}


.logo-and-nav {
  display: flex;
  align-items: center;
}

.nav-links a {
  margin-left: 20px;
}


/* responsive sidebar */

.sidebar-responsive-bg-col .close-btn-sidebar-responsive {
  position: absolute;
  right: 0px;
  top: 0px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #a07e00;
  border: 1px solid #c1c1c1;
  border-radius: 3px;
  z-index: 999;
  cursor: pointer;
}

.sidebar-responsive-bg-col .close-btn-sidebar-responsive img {
  width: 15px;
  height: 15px;
  object-fit: contain;
  filter: invert(1) brightness(12);
}

.sidebar-responsive-bg-col .MuiPaper-root {
  background: #00a684;
}

.sidebar-responsive-bg-col ul a:first-child {
  padding-top: 50px;
}

.sidebar-responsive-bg-col ul a {
  color: #fff;
  font-size: 17px;
  line-height: 24px;
  margin-bottom: 10px;
  border-bottom: 1px solid #f7f7f7;
  padding: 12px 20px;
}

/* scrollbar sidebar */
.sidebar-responsive-bg-col .MuiPaper-root::-webkit-scrollbar {
  width: 3px;
}

/* Handle */
.sidebar-responsive-bg-col .MuiPaper-root::-webkit-scrollbar-thumb {
  background: rgb(93, 65, 255);
  border-radius: 5px;
}

/* student submit tests */

.student-tests-submitted-main-sec {
  padding: 40px 10px;
  background: url("../src/assets/images/cmn-all-website-bnr-bg.jpg") no-repeat;
  background-size: cover;
  background-position: center;
}

.test-submitted-inr-main-sec {
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 30px;
  padding-bottom: 8px;
}

.test-submitted-inr-main-sec h3 {
  font-size: 22px;
  color: #ffffff;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 12px;
}

.test-submitted-inr-main-sec h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 12px;
}

.col-gaps-submitted-results {
  box-shadow: 0 0 8px #fff6c0;
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  margin-bottom: 30px;
  font-size: 22px;
  font-weight: 600;
  color: #5a0100;
  line-height: 24px;
  max-height: 400px;
  overflow-y: auto;
}

.col-gaps-submitted-results h5 p {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin: 12px 0;
  color: #002761;
}


.col-gaps-submitted-results p {
  font-size: 16px;
  font-weight: 400;
  color: #2a2a2a;
  line-height: 24px;
  margin: 14px 0;
}

.col-gaps-submitted-results::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.col-gaps-submitted-results::-webkit-scrollbar-track {
  box-shadow: inset 0 0 20px rgb(174, 168, 255);
  border-radius: 10px;
}

.col-gaps-submitted-results::-webkit-scrollbar-thumb {
  background: rgb(73, 41, 255);
  border-radius: 5px;
  height: 40px;
}

@media (max-width: 991px) {

  .col-gaps-submitted-results {
    padding: 15px;
    font-size: 20px;
  }

  .col-gaps-submitted-results h5 p {
    font-size: 16px;
  }


  .col-gaps-submitted-results p {
    font-size: 14px;
  }
}

/* dashboard */

.dashboard-main-sec {
  background: url('../src/assets/images/cmn-all-website-bnr-bg.jpg') no-repeat;
  /* postion size inline e code e likha ache */
}



/* profile page */

.profile-main-sec {
  background: url('../src/assets/images/cmn-all-website-bnr-bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  padding: 60px 0;
  min-height: 90vh;
}

.profile-inr-sec {
  max-width: 600px;
  margin: 0 auto;
}

.profile-upload-img-sec {
  text-align: center;
}

.profile-upload-img-sec figure {
  width: 200px;
  height: 200px;
  border: 10px solid #b2bfff;
  margin: 0 auto;
  border-radius: 50%;
  /* overflow: hidden; */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;
}

.edit-onlyprofile-btn-one {
  position: absolute;
  right: 8px;
  bottom: 1px;
  z-index: 10;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  background: transparent;
}

.profile-upload-img-sec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.profile-details-sec-inr {
  box-shadow: 0 0 20px #001e4c;
  padding: 110px 20px 35px;
  border-radius: 15px;
  margin-top: -85px;
  background: #fff;
}

.profile-details-sec-inr input {
  width: 100%;
  border: 1px solid #6db9ff;
  padding: 12px 10px;
  border-radius: 5px;
  outline: none !important;
  transition: 0.5s;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  color: #00113f;
  line-height: 26px;
}

.profile-details-sec-inr input:focus,
.profile-details-sec-inr input:focus-visible {
  border: 1px solid #001dc3;
}

.profile-details-sec-inr .form-group-profile {
  margin-bottom: 30px;
  position: relative;
}

.edit-profile-btn-one {
  background-image: linear-gradient(to right, #c21500 0%, #ffc500 51%, #c21500 100%);
  padding: 12px 25px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 12px #97b0f5;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  border: 1px solid #ffc500;
}

.edit-profile-btn-one:hover,
.update-profile-btn-one:hover {
  background-position: right center;
}


.update-profile-btn-one {
  background-image: linear-gradient(to right, #6A9113 0%, #141517 51%, #6A9113 100%);
  padding: 12px 25px;
  text-align: center;
  text-transform: capitalize;
  transition: 0.5s;
  background-size: 200% auto;
  color: #fff;
  box-shadow: 0 0 12px #97b0f5;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 25px;
  border: 1px solid #6A9113;
}

.last-group-btn-sec-profile {
  text-align: center;
  padding-top: 20px;
}

.last-group-btn-sec-profile button {
  margin-bottom: 15px;
}

.last-group-btn-sec-profile button:first-child {
  margin-right: 10px;
}

.edit-update-profile-btn-one {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
}

.edit-update-profile-btn-one img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.edit-update-profile-btn-two {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
}

.edit-update-profile-btn-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.close-update-profile-btn-two {
  position: absolute;
  right: 45px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: transparent;
}

.close-update-profile-btn-two img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 500px) {
  .profile-inr-sec {
    max-width: 100%;
  }

  .last-group-btn-sec-profile button {
    width: 100%;
  }

  .profile-upload-img-sec figure {
    width: 180px;
    height: 180px;
  }
}

.answersInnerDiv {
  overflow-y: scroll;
  height: inherit;
  padding: 10px;
}

/* Custom Scroll */
.answersInnerDiv::-webkit-scrollbar {
  width: 5px;
}

.answersInnerDiv::-webkit-scrollbar-thumb {
  /* background: #bcbcbc; */
  background: #99d41a;
  border-radius: 10px;
}

.answersInnerDiv::-webkit-scrollbar-track {
  background: #97a1812e;
  border-radius: 10px;
}

/* Custom Scroll */

/* Study Guides Page */

.studyGuides-main-sec {
  padding: 25px 0;
  background: url('../src/assets/images/cmn-all-website-bnr-bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 90vh;
}

/* About Us Page */

.aboutUs-main-sec {
  /* padding: 25px 0; */
  padding: 45px 0;
  background: url('../src/assets/images/cmn-all-website-bnr-bg.jpg') no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 90vh;
}

/* about us static content */

.contact-information-aboutpages {
  padding: 40px 0;
}

.AnchorTagsStyle {
  color: rgb(2 0 185);
  text-decoration: underline;
  font-size: 20px;
  transition: 0.5s;
  text-shadow: 0px 1px 2px #000a32;
}

.AnchorTagsStyle:hover {
  color: #02bfa0;
  text-shadow: none;
}

.test-heading {    
  text-align: center;
  margin-bottom: 25px;
  color: #fff;
  text-shadow: 1px 2px 4px #000000;
}

/* Custom Scroll */
.ref-list-ul::-webkit-scrollbar {
  width: 5px;
}

.ref-list-ul::-webkit-scrollbar-thumb {
  /* background: #bcbcbc; */
  background: #99d41a;
  border-radius: 10px;
}

.ref-list-ul::-webkit-scrollbar-track {
  background: #97a1812e;
  border-radius: 10px;
}

/* Custom Scroll */