@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Forum&display=swap");

button:focus,
a.btn:focus,
.btn:focus {
  outline: none;
  border: 0;
  box-shadow: none;
}

input.form-control:focus {
  box-shadow: none;
}

section.adminLogin {
   background: url(/src/assets/images/bg.jpg) no-repeat center;
    background-size: cover;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    position: relative;
    z-index: 1;
}
section.adminLogin::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: -1;
}
.loginOuterBoxTop{
    max-width: 800px;
}
h1.h3.mb-3.asvabHeading {
    font-size: 50px;
    font-weight: 800;
}
.formBoxTextBtn {
    font-size: 25px;
}
span.btnText {
    font-weight: 700;
    color: #fae464;
}
.adminLogin .loginOuterBox {
  background: #fff0;
  padding:30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  /* text-align: center; */
}
.LGin {
    font-weight: 700;
    margin: 20px 0px;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  border-width: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
}
.form-signin .form-control::placeholder {
  color: #333;
  /* opacity: 1; */
}
.btnGroup {
  display: grid;
  gap: 10px;
}
.formBoxs {
  display: grid;
  gap: 25px;
  margin-bottom: 25px;
}
button.btn.btn-outline-light.btn-lg.btn-block.form-group-3 {
  height: 45px;
  font-size: 20px;
}
.logoImg {
  width: 100%;
  margin-bottom: 20px;
}

@media (max-width: 567px) {
  section.adminLogin {
    height: 100% !important;
  }
  }